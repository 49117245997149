.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1f2kw6w-MuiContainer-root {
  max-width: 100% !important;
}

/*TODO: verify in prod (width 100% in grid)*/
.MuiContainer-root {
  max-width: 100% !important;
}

.actionGridContainer {
  margin-top: 10px;
  width: 100%;

  .actionGrid {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 10px;
    background-color: #fff;

    .buttonActionUpdateMultiple {
      margin-right: 10px;
    }

    .buttonActionAdd {
      margin-right: 0px;
    }
  }
}

.buttonActionEdit {
  margin-right: 10px !important;
}

.buttonActionLast {
  margin-right: 10px;
}

#toast-container {
  margin-top: 6rem;
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 9999;
}

#toast-container > .toast-top-full-width > .toast {
  max-width: 570px;
}

#toast-container > .toast-success {
  background-color: green;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 50px !important;
  padding: 10px;
  color: #fff;
}

#toast-container > .toast-success:hover {
  background-color: #35c8c3;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.css-cvumne-MuiDrawer-docked .MuiDrawer-paper {
  width: 350px !important;
}

/*TODO: verify in prod (drawer)*/
.MuiDrawer-root > .MuiPaper-root {
  width: 350px !important;
}

.css-n7rtur-MuiPaper-root-MuiAppBar-root {
  width: calc(100% - 350px) !important;
}

.MuiFormHelperText-root {
  position: absolute;
  top: 55px;
  color: red !important;
}

.MuiDataGrid-cell--withRenderer {
  display: flex;
  justify-content: right;
}

.divSearchButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 23px;
  padding-bottom: 12px;
  width: 17%;
  min-width: 250px;
  align-content: baseline;
}

.divSearchButtonLarge {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 30px;
  padding-bottom: 5px;
  width: 30%;
  min-width: 400px;
  align-content: baseline;
}

.divFormRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
